import type { ModalOverlayProps as RACModalOverlayProps } from "react-aria-components";

import {
  composeRenderProps,
  Modal as RACModal,
  ModalOverlay as RACModalOverlay,
} from "react-aria-components";
import { twMerge } from "tailwind-merge";

type ClassNames = {
  modal?: RACModalOverlayProps["className"];
  modalOverlay?: RACModalOverlayProps["className"];
};

type ModalProps = {
  animate?: boolean;
  classNames?: ClassNames;
} & Omit<RACModalOverlayProps, "className">;

export function Modal({ animate = true, classNames, ...props }: ModalProps) {
  return (
    <RACModalOverlay
      {...props}
      className={composeRenderProps(
        classNames?.modalOverlay,
        (className, renderProps) =>
          twMerge(
            "fixed left-0 top-0 isolate z-20",
            "h-[--visual-viewport-height] w-full",
            "bg-gray-950/30",
            "flex items-end text-center sm:items-center",
            "justify-center",
            "pt-4 [--visual-viewport-vertical-padding:16px] sm:p-4 sm:[--visual-viewport-vertical-padding:32px]",
            renderProps.isEntering &&
              "duration-200 ease-out animate-in fade-in",
            renderProps.isExiting &&
              "duration-200 ease-in animate-out fade-out",
            className,
          ),
      )}
    >
      <RACModal
        {...props}
        className={composeRenderProps(
          classNames?.modal,
          (className, renderProps) =>
            twMerge(
              "max-h-full w-full overflow-hidden text-left align-middle shadow-xl",
              "bg-white",
              "w-full",
              "w-[544px]",
              "rounded-t-2xl sm:rounded-xl",
              animate &&
                renderProps.isEntering &&
                "duration-200 ease-out animate-in slide-in-from-bottom sm:zoom-in-105 sm:slide-in-from-bottom-0",
              animate &&
                renderProps.isExiting &&
                "duration-200 ease-in animate-out slide-out-to-bottom sm:zoom-out-95 sm:slide-out-to-bottom-0",
              className,
            ),
        )}
      />
    </RACModalOverlay>
  );
}
