import type { LinkProps as RACLinkProps } from "react-aria-components";

import React from "react";
import { composeRenderProps, Link as RACLink } from "react-aria-components";
import { twMerge } from "tailwind-merge";

import type { AsChildProps } from "../slot";

import { Slot } from "../slot";
import { focusOutlineStyle } from "../utils";

export type LinkProps = AsChildProps<RACLinkProps>;

const linkStyle = [
  "relative inline-flex cursor-pointer items-center gap-1 rounded-lg outline-none hover:underline",
  "text-base/6 sm:text-sm/6",
  "disabled:no-underline disabled:opacity-50 disabled:cursor-default",
  "[&.border]:hover:no-underline",
];

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  function Link(props, ref) {
    if (props.asChild) {
      return <Slot className={twMerge(linkStyle)}>{props.children}</Slot>;
    }

    const { asChild: _, ...rest } = props;

    return (
      <RACLink
        {...rest}
        className={composeRenderProps(
          props.className,
          (className, renderProps) =>
            twMerge([
              linkStyle,
              renderProps.isFocusVisible && focusOutlineStyle,
              className,
            ]),
        )}
        ref={ref}
      />
    );
  },
);
