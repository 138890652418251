import wretch from "wretch";
import QueryStringAddon from "wretch/addons/queryString";

import { env } from "@/config/env";

const BASE_URL = env.VITE_BASE_API_URL;

const baseApi = wretch(`${BASE_URL}/api`)
  .addon(QueryStringAddon)
  .options({
    credentials: "include",
  })
  .catcherFallback((error) => {
    throw error.json;
  });

export default baseApi;
