import { createFileRoute } from "@tanstack/react-router";
import { useFeatureFlagEnabled } from "posthog-js/react";

export const Route = createFileRoute("/feature")({
  component: Component,
});

function Component() {
  const showFlaggedFeature = useFeatureFlagEnabled(
    "react-fe-test-feature-flag",
  );

  return (
    <div>
      {showFlaggedFeature ? (
        <div>Feature flag enabled</div>
      ) : (
        <div>Feature flag disabled</div>
      )}
    </div>
  );
}
