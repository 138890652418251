import { DetailedForm } from "./DetailedForm";
import useAIStream from "./hooks/useAIStream";
import { useCreateRequestForm } from "./hooks/useCreateRequestForm";
import { MagicalBriefCreator } from "./MagicalBriefCreator";
import { SingleInputForm } from "./SingleInputForm";
import { TabsHeader } from "./TabsHeader";

export function BriefCreatorSection() {
  const {
    detailedMethods,
    filledMandatoryFields,
    formType,
    handleFormTypeChange,
    onSubmit,
    singleInputMethods,
    totalMandatoryFields,
  } = useCreateRequestForm();

  const aiStreamUtils = useAIStream();

  return (
    <div className="grid grid-cols-2 grid-rows-[min-content_min-content] gap-x-3xl gap-y-lg">
      <TabsHeader
        formType={formType}
        handleFormTypeChange={handleFormTypeChange}
        isDisabled={Boolean(aiStreamUtils.streamedResponse)}
      />

      {formType === "detailed" && (
        <>
          <MagicalBriefCreator
            filledMandatoryFields={filledMandatoryFields}
            onSubmit={onSubmit}
            totalMandatoryFields={totalMandatoryFields}
            {...aiStreamUtils}
          />
          <DetailedForm
            isDisabled={Boolean(aiStreamUtils.streamedResponse)}
            methods={detailedMethods}
          />
        </>
      )}
      {formType === "single" && (
        <SingleInputForm methods={singleInputMethods} />
      )}
    </div>
  );
}
