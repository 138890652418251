import { useMutation } from "@tanstack/react-query";
import { useRef, useState } from "react";

import type { DetailedFormData, MappedSingleInputFormData } from "../schemas";

import { createOutputEnhancement } from "../api/createOutputEnhancement";

function useAIStream() {
  const [streamedResponse, setStreamedResponse] = useState("");
  const abortControllerRef = useRef<AbortController | null>(null);

  const { error, isError, isPending, mutate } = useMutation({
    mutationFn: async ({
      data,
      type,
    }: {
      data: DetailedFormData | MappedSingleInputFormData;
      type: "detailed" | "single";
    }) => {
      setStreamedResponse("");
      abortControllerRef.current = new AbortController();

      const response = await createOutputEnhancement(
        data,
        abortControllerRef.current,
        type === "detailed",
        true,
      );

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();

      if (reader) {
        try {
          while (true) {
            const { done, value } = await reader.read();

            if (done) break;
            const chunk = decoder.decode(value, { stream: true });

            setStreamedResponse((previous) => previous + chunk);
          }
        } finally {
          reader.releaseLock();
        }
      }

      return response;
    },
    mutationKey: ["createOutputEnhancement"], // TODO: find scalable solution for handling query keys
  });

  function resetStreamedResponse() {
    setStreamedResponse("");
  }

  function sendPrompt(
    data: DetailedFormData | MappedSingleInputFormData,
    type: "detailed" | "single",
  ) {
    resetStreamedResponse();
    mutate({ data, type });
  }

  function abortStream() {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  }

  return {
    abortStream,
    error,
    isError,
    isPending,
    resetStreamedResponse,
    sendPrompt,
    streamedResponse,
  };
}

export default useAIStream;
