import { Download, File, FileWarning, Loader, Trash2 } from "lucide-react";

import { Button } from "@/components/button";

import type { FileAsset } from "../useAssetsSection";

function handleDownload(file: File) {
  const url = URL.createObjectURL(file);
  const a = document.createElement("a");

  a.href = url;
  a.download = file.name;
  document.body.append(a);
  a.click();
  URL.revokeObjectURL(url);
  a.remove();
}

type UploadedAssetProps = {
  file: FileAsset;
  onDelete: (fileName: string) => void;
};

function UploadingButtons() {
  return (
    <Button
      className="text-gray-600"
      color="gray"
      isIconOnly
      size="sm"
      variant="tertiary"
    >
      <Loader className="animate-spin text-brand-700" />
    </Button>
  );
}

function SuccessButtons({ file, onDelete }: UploadedAssetProps) {
  return (
    <>
      <Button
        color="gray"
        isIconOnly
        onPress={() => onDelete(file.awsKey)}
        size="sm"
        variant="tertiary"
      >
        <Trash2 className="text-gray-600" />
      </Button>
      <Button
        className="text-gray-600"
        color="gray"
        isIconOnly
        onPress={() => handleDownload(file.file)}
        size="sm"
        variant="tertiary"
      >
        <Download className="text-brand-700" />
      </Button>
    </>
  );
}

function ErrorButtons({ file, onDelete }: UploadedAssetProps) {
  return (
    <>
      <Button
        color="gray"
        isIconOnly
        onPress={() => onDelete(file.awsKey)}
        size="sm"
        variant="tertiary"
      >
        <Trash2 className="text-gray-600" />
      </Button>
      <Button
        className="text-gray-600"
        color="gray"
        isDisabled
        isIconOnly
        onPress={() => handleDownload(file.file)}
        size="sm"
        variant="tertiary"
      >
        <FileWarning className="text-red-500" />
      </Button>
    </>
  );
}

export function UploadedAsset({ file, onDelete }: UploadedAssetProps) {
  return (
    <div className="flex items-center justify-between gap-xs rounded-xl border border-gray-200 bg-white px-xl py-md">
      <div className="flex items-center gap-md overflow-hidden">
        <File className="shrink-0 text-gray-400" size={16} />
        <span
          className="truncate text-sm font-medium text-gray-700"
          title={file.file.name}
        >
          {file.file.name}
        </span>
      </div>
      <div className="flex shrink-0 gap-xs">
        {file.status === "uploading" && <UploadingButtons />}
        {file.status === "success" && (
          <SuccessButtons file={file} onDelete={onDelete} />
        )}
        {file.status === "error" && (
          <ErrorButtons file={file} onDelete={onDelete} />
        )}
      </div>
    </div>
  );
}
