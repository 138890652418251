import Logo from "@/assets/logo.svg?react";

interface AuthContainerProps {
  children: React.ReactNode;
  description: string;
  footer?: React.ReactNode;
  title: string;
}

export function AuthContainer({
  children,
  description,
  footer,
  title,
}: AuthContainerProps) {
  return (
    <div className="grid h-screen place-items-center bg-gray-100">
      <div className="flex flex-col items-center gap-4xl">
        <div className="flex w-[384px] flex-col gap-3xl rounded-md bg-white p-3xl">
          <div className="flex flex-col gap-5xl">
            <div className="flex flex-col items-center gap-2xl">
              <Logo className="size-4xl" />
              <h1 className="text-display-sm font-semibold">{title}</h1>
              <span className="text-center text-md text-gray-500">
                {description}
              </span>
            </div>
            {children}
          </div>
        </div>
        {footer && <div className="flex gap-3xl">{footer}</div>}
      </div>
    </div>
  );
}
