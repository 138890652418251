import { CloudUpload } from "lucide-react";

export function UploadAssetsInput() {
  return (
    <div className="flex flex-col items-center gap-lg">
      <div className="grid size-5xl place-items-center rounded-md border border-gray-200">
        <CloudUpload className="text-gray-700" size={16} />
      </div>
      <div className="flex flex-col gap-xs text-center">
        <span className="text-sm font-semibold text-brand-700">
          Click to upload
        </span>
        <span className="text-xs text-gray-600">
          Share asset files or links to use as reference for inspiration. When
          sharing links please grant access to admin@nolimitcreatives.com.
        </span>
      </div>
    </div>
  );
}
