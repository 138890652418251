import { useSearch } from "@tanstack/react-router";
import { nanoid } from "nanoid";
import { useState } from "react";

import {
  deleteFileFromServer,
  findFileAssetByAwsKey,
  markAssetAsError,
  removeAssetFromState,
} from "./utils/stateUpdateHelpers";
import { MAX_FILES, uploadFile } from "./utils/uploadUtils";

type FileStatus = "error" | "success" | "uploading";

export type FileAsset = {
  awsKey: string;
  file: File;
  id: string;
  status: FileStatus;
};

export type LinkAsset = {
  id: string;
  url: string;
};

export function useAssetsSection(): {
  assets: (FileAsset | LinkAsset)[];
  error: null | string;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileDelete: (fileId: string) => Promise<void>;
  handleLinkAdd: (url: { link: string }) => void;
  handleLinkDelete: (linkId: string) => void;
} {
  const { ["project-id"]: projectId } = useSearch({
    from: "/requests/create",
  });

  const [error, setError] = useState<null | string>(null);
  const [assets, setAssets] = useState<Array<FileAsset | LinkAsset>>([]);

  async function handleFileDelete(awsKey: string) {
    setError(null);

    const fileAsset = findFileAssetByAwsKey(assets, awsKey);

    if (!fileAsset) {
      console.error(`File not found`);

      return;
    }

    try {
      await deleteFileFromServer(fileAsset.awsKey);
      setAssets((prev) => removeAssetFromState(prev, awsKey));
    } catch (error) {
      console.error(`Error deleting file ${awsKey}:`, error);
      setAssets((prev) => markAssetAsError(prev, awsKey));
      setError(
        `Failed to delete file ${fileAsset.file.name}. Please try again.`,
      );
    }
  }

  async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    setError(null);

    if (!event.target.files) {
      return;
    }

    const uploadedFiles = [...event.target.files];

    if (uploadedFiles.length + assets.length > MAX_FILES) {
      setError(`You can only upload a maximum of ${MAX_FILES} files.`);

      return;
    }

    const newAssets = uploadedFiles.map((file) => ({
      awsKey: "",
      file,
      id: nanoid(),
      status: "uploading" as const,
    }));

    setAssets((prev) => [...newAssets, ...prev]);

    for (const newFile of newAssets) {
      await uploadFile(newFile, setAssets, setError, projectId);
    }
  }

  function handleLinkAdd(url: { link: string }) {
    setError(null);

    setAssets((prev) => [{ id: nanoid(), url: url.link }, ...prev]);
  }

  function handleLinkDelete(linkId: string) {
    setError(null);

    setAssets((prev) => prev.filter((f) => f.id !== linkId));
  }

  return {
    assets,
    error,
    handleFileChange,
    handleFileDelete,
    handleLinkAdd,
    handleLinkDelete,
  };
}
