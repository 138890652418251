import type { DefaultError } from "@tanstack/react-query";

import { useQuery } from "@tanstack/react-query";
import { useSearch } from "@tanstack/react-router";
import { useListData } from "react-stately";

import { Description, Label } from "@/components/field";
import {
  MultiSelect,
  MultiSelectField,
  MultiSelectItem,
} from "@/components/multi-select";
import { Select, SelectButton } from "@/components/select";
import { Tag } from "@/components/tag-group";
import apiClient from "@/lib/api/client";

// TODO: replace with shared types when ready
type Collaborator = {
  id: string;
  textValue: string;
};

type Response = {
  collaborators: {
    id: string;
    name: string;
  }[];
};

type MappedResponse = Collaborator[];

function useGetCollaborators(projectId: number) {
  return useQuery<Response, DefaultError, MappedResponse>({
    queryFn: async () =>
      await apiClient.url(`/projects/${projectId}/collaborators`).get().json(),
    queryKey: ["collaborators"],
    select: (data) =>
      data.collaborators.map(({ id, name }) => ({ id, textValue: name })),
  });
}

export function CollaboratorsSelect() {
  const { ["project-id"]: projectId } = useSearch({
    from: "/requests/create",
  });

  const { data } = useGetCollaborators(projectId);

  const selectedList = useListData<Collaborator>({
    initialItems: [],
  });

  if (data) {
    return (
      <MultiSelectField>
        <Label>Collaborators</Label>
        <MultiSelect
          items={data}
          placeholder="Select"
          renderEmptyState={(inputValue) => (
            <span className="p-2">
              {inputValue ? (
                <>No results found for: {inputValue}</>
              ) : (
                `No collaborators available`
              )}
            </span>
          )}
          selectedList={selectedList}
          tag={(item) => <Tag textValue={item.id}>{item.textValue}</Tag>}
        >
          {(item) => (
            <MultiSelectItem textValue={item.id}>
              {item.textValue}
            </MultiSelectItem>
          )}
        </MultiSelect>
        <Description>
          Adding a collaborator will give them access to view project details,
          uploaded project files and comments between our team and yours. Please
          make sure you are only adding collaborators who you want to access
          this project fully.
        </Description>
      </MultiSelectField>
    );
  }

  return (
    <Select placeholder="Loading...">
      <Label>Collaborators</Label>
      <SelectButton />
      <Description>
        Adding a collaborator will give them access to view project details,
        uploaded project files and comments between our team and yours. Please
        make sure you are only adding collaborators who you want to access this
        project fully.
      </Description>
    </Select>
  );
}
