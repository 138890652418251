import type { WretchResponse } from "wretch";

import AbortAddon from "wretch/addons/abort";
import QueryStringAddon from "wretch/addons/queryString";

import apiClient from "@/lib/api/client";

import type { DetailedFormData, MappedSingleInputFormData } from "../schemas";

export async function createOutputEnhancement(
  data: DetailedFormData | MappedSingleInputFormData,
  abortController: AbortController,
  isDetailed: boolean,
  isStream = true,
): Promise<WretchResponse> {
  return apiClient
    .url("/brief-creator/output-enhancement")
    .addon(AbortAddon())
    .signal(abortController)
    .addon(QueryStringAddon)
    .query({ detailed: isDetailed, stream: isStream })
    .post({ prompt: data })
    .res();
}
