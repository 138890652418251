import Placeholder from "@tiptap/extension-placeholder";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";

import type { TiptapEditorProps } from ".";

export function useInitializeEditor({
  className,
  isDisabled,
  onChange,
  onChangeHTML,
  placeholder,
  value,
  ...editorProps
}: { className?: string } & TiptapEditorProps) {
  return useEditor({
    content: value,
    editable: !isDisabled,
    editorProps: {
      attributes: {
        class: twMerge(
          clsx(
            "prose min-h-[124px] overflow-hidden overflow-y-auto rounded-md px-[14px] py-lg outline-none",
            isDisabled && "bg-gray-50 text-gray-500",
            !isDisabled && "bg-white",
            className,
          ),
        ),
        "data-testid": "tiptap-editor",
      },
    },
    extensions: [
      StarterKit,
      Placeholder.configure({
        emptyEditorClass:
          "cursor-text before:pointer-events-none before:absolute before:inset-x-[14px] before:text-gray-500 before:content-[attr(data-placeholder)]",
        placeholder: placeholder,
        showOnlyWhenEditable: false,
      }),
    ],
    onUpdate: ({ editor }) => {
      onChange?.(
        editor.getText({
          blockSeparator: "", // removes \n from text
        }),
      );
      onChangeHTML?.(editor.getHTML());
    },
    ...editorProps,
  });
}
