export type DesignTypeSubCategory = {
  category: string;
  id: number;
  name: string;
};

export const designTypeSubCategories: DesignTypeSubCategory[] = [
  {
    category: "graphic",
    id: 1,
    name: "Digital Ads",
  },
  {
    category: "graphic",
    id: 2,
    name: "Social Content",
  },
  {
    category: "graphic",
    id: 3,
    name: "Amazon/Ecom",
  },
  {
    category: "graphic",
    id: 4,
    name: "Email Graphics",
  },
  {
    category: "graphic",
    id: 5,
    name: "Web",
  },
  {
    category: "graphic",
    id: 6,
    name: "Vector Illustrations",
  },
  {
    category: "graphic",
    id: 7,
    name: "Packaging",
  },
  {
    category: "graphic",
    id: 8,
    name: "Logo & Branding",
  },
  {
    category: "graphic",
    id: 9,
    name: "Print Media",
  },
  {
    category: "graphic",
    id: 10,
    name: "PDF & Presentations",
  },
  {
    category: "video",
    id: 11,
    name: "Motion Graphic Ads",
  },
  {
    category: "video",
    id: 12,
    name: "Advanced Motion Graphics",
  },
  {
    category: "video",
    id: 13,
    name: "Amazon/Ecom",
  },
  {
    category: "video",
    id: 14,
    name: "Video Editing",
  },
] as const;
