import type { UseFormReturn } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

const loginFormSchema = z.object({
  email: z
    .string()
    .min(1, "Please fill in this field.")
    .email("Please enter a valid email address."),
  password: z.string().min(1, "Please fill in this field."),
  remember: z.boolean(),
});

const loginSubmissionSchema = loginFormSchema.extend({
  remember: z.boolean().transform((value) => (value ? 1 : 0)),
});

export type LoginFormData = z.infer<typeof loginFormSchema>;
export type LoginSubmissionData = z.infer<typeof loginSubmissionSchema>;

export function useLoginForm(): UseFormReturn<LoginFormData> {
  return useForm<LoginFormData>({
    defaultValues: {
      email: "",
      password: "",
      remember: false,
    },
    resolver: zodResolver(loginFormSchema),
  });
}

export function transformLoginData(data: LoginFormData): LoginSubmissionData {
  return loginSubmissionSchema.parse(data);
}
