import type { Editor } from "@tiptap/react";
import type { ForwardedRef } from "react";

import { EditorContent } from "@tiptap/react";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";

import { MenuBar } from "./MenuBar";

export function EditorContainer({
  editor,
  isDisabled,
  isInvalid,
  placeholder,
  ref,
}: {
  editor: Editor;
  isDisabled?: boolean;
  isInvalid?: boolean;
  placeholder?: string;
  ref: ForwardedRef<HTMLDivElement>;
}) {
  return (
    <div
      className={twMerge(
        clsx(
          "flex flex-col overflow-hidden rounded-md border border-gray-300",
          {
            "border-brand-300": Boolean(editor.isFocused) && !isInvalid,
            "border-gray-300 ": Boolean(isDisabled),
            "border-red-300": isInvalid,
          },
          {
            "outline outline-4": Boolean(editor.isFocused),
            "outline-brand-200": Boolean(editor.isFocused) && !isInvalid,
            "outline-red-200": Boolean(editor.isFocused) && isInvalid,
          },
        ),
      )}
    >
      <MenuBar disabled={Boolean(isDisabled)} editor={editor} />
      <EditorContent
        editor={editor}
        placeholder={placeholder}
        ref={ref}
        role="textbox"
      />
    </div>
  );
}
