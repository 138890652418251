import type { Key } from "react-aria-components";

import { useIsMutating } from "@tanstack/react-query";
import { twMerge } from "tailwind-merge";

import { Alert } from "@/components/alert";
import { Radio, RadioGroup, Radios } from "@/components/radio-group";

import { useAlert } from "./hooks/useAlert";
type TabsHeaderProps = {
  formType: Key;
  handleFormTypeChange: (key: Key) => void;
  isDisabled: boolean;
};

export function TabsHeader({
  formType,
  handleFormTypeChange,
  isDisabled,
}: TabsHeaderProps) {
  const { hideAlert, isAlertHidden } = useAlert("check-mbf");

  const isPending = Boolean(
    useIsMutating({
      mutationKey: ["createOutputEnhancement"], // TODO: find scalable solution for handling query keys
      status: "pending",
    }),
  );

  const isBlocked = isDisabled || isPending;

  const options = [
    {
      description: "Splited into detailed categories",
      name: "Magical Brief Form",
      value: "detailed",
    },
    {
      description: "Legacy version of brief creator",
      name: "Simple Form",
      value: "single",
    },
  ] as const;

  return (
    <div className="col-span-1 row-span-1 flex w-full flex-col gap-[10px]">
      <span className="text-lg font-semibold text-gray-700">Brief Creator</span>
      {isAlertHidden ? null : (
        <Alert
          description="Check the new Magical Brief Form and let the AI help you!"
          onClick={hideAlert}
          title="Creating your request from scratch?"
        />
      )}
      <RadioGroup onChange={handleFormTypeChange} value={formType as string}>
        <Radios className="grid grid-cols-2 gap-[10px]">
          {options.map((option) => (
            <Radio
              className={({ isSelected }) =>
                twMerge(
                  "items-start rounded-xl border p-xl [&_[slot=radio]]:mt-[3px]",
                  isSelected &&
                    "border-brand-600 ring-2 ring-brand-600 disabled:border-gray-200 disabled:ring-gray-200",
                  "disabled:bg-gray-50",
                )
              }
              isDisabled={isBlocked}
              key={option.value}
              value={option.value}
            >
              <div className="flex flex-1 flex-col gap-0">
                <div className="text-sm font-medium text-gray-700">
                  {option.name}
                </div>
                <div className="text-sm text-gray-600">
                  {option.description}
                </div>
              </div>
            </Radio>
          ))}
        </Radios>
      </RadioGroup>
    </div>
  );
}
