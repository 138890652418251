import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { CreateRequestForm } from "@/features/request/components/CreateRequestForm";

const requestCreateSchema = z.object({
  ["project-id"]: z.number(),
});

// temporary route for creating a new request
export const Route = createFileRoute("/requests/create")({
  component: () => (
    <main className="min-h-screen bg-gray-100 p-4xl">
      <CreateRequestForm />
    </main>
  ),
  validateSearch: requestCreateSchema,
});
