import { Button } from "@/components/button";

import ChevronLeftIcon from "../assets/chevron-left.svg?react";

export function Header() {
  return (
    <div className="flex items-center justify-start gap-2xl border-b border-gray-200 px-3xl pb-2xl pt-xl">
      <Button color="gray" isIconOnly size="lg" variant="secondary">
        <ChevronLeftIcon aria-label="Back" />
      </Button>
      <span className="text-display-xs font-semibold text-gray-900">
        Request Name
      </span>
    </div>
  );
}
