import type {
  ListBoxItemProps,
  ListBoxProps as RACListBoxProps,
} from "react-aria-components";

import React from "react";
import {
  composeRenderProps,
  ListBox as RACListBox,
  ListBoxItem as RACListBoxItem,
} from "react-aria-components";
import { twMerge } from "tailwind-merge";

import { composeTailwindRenderProps, focusOutlineStyle } from "../utils";

export type ListBoxProps<T> = Omit<
  RACListBoxProps<T>,
  "layout" | "orientation"
>;

export function ListBox<T extends object>({
  children,
  ...props
}: ListBoxProps<T>) {
  const ref = React.useRef<HTMLDivElement>(null);

  // Fix not auto scroll to selected item
  React.useEffect(() => {
    if (ref.current) {
      const selectedItem = ref.current.querySelector("[aria-selected=true]");

      if (selectedItem) {
        const timer = setTimeout(() => {
          selectedItem.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }, 50);

        return () => {
          clearTimeout(timer);
        };
      }
    }
  }, []);

  return (
    <RACListBox
      {...props}
      className={composeTailwindRenderProps(props.className, ["outline_none"])}
      ref={ref}
    >
      {children}
    </RACListBox>
  );
}

export function ListBoxItem(props: ListBoxItemProps) {
  const textValue =
    props.textValue ||
    (typeof props.children === "string" ? props.children : undefined);

  return (
    <RACListBoxItem
      {...props}
      className={composeRenderProps(
        props.className,
        (className, { isDisabled, isFocusVisible }) =>
          twMerge(
            "group relative flex outline-0",
            isDisabled && "opacity-50",
            isFocusVisible && focusOutlineStyle,
            className,
          ),
      )}
      textValue={textValue}
    >
      {props.children}
    </RACListBoxItem>
  );
}
