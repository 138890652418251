import apiClient from "@/lib/api/client";

import { updateFileById } from "./stateUpdateHelpers";

export type FileStatus = "error" | "success" | "uploading";

export type FileAsset = {
  awsKey: string;
  file: File;
  id: string;
  status: FileStatus;
};

export type LinkAsset = {
  id: string;
  url: string;
};

export const MAX_FILE_SIZE = 200 * 1024 * 1024; // 200MB
export const MAX_FILES = 25;
export const ALLOWED_EXTENSIONS = [
  ".jpg",
  ".png",
  ".jpeg",
  ".pdf",
  ".heic",
  ".svg",
  ".eps",
  ".webp",
  ".tiff",
  ".gif",
  ".mp4",
  ".m4v",
  ".psd",
  ".ai",
  ".indd",
  ".prproj",
  ".aep",
  ".fig",
  ".mp3",
  ".wav",
  ".bmp",
  ".xd",
  ".html",
  ".doc",
  ".docx",
  ".txt",
  ".ppt",
  ".key",
  ".numbers",
  ".pages",
  ".cdr",
  ".raw",
  ".mid",
  ".midi",
  ".m4a",
  ".ogg",
  ".avi",
  ".mpg",
  ".mpeg",
  ".m4v",
  ".rar",
  ".zip",
  ".xls",
  ".xlsx",
  ".csv",
  ".mov",
  ".ttf",
  ".otf",
  ".pptx",
];

export const isFileAsset = (asset: FileAsset | LinkAsset): asset is FileAsset =>
  "file" in asset;

export async function getUploadUrlFields(
  fileName: string,
  projectId: number,
  requestId: string,
): Promise<{ fields: Record<string, string>; url: string }> {
  return apiClient
    .query({ fileName, projectId, requestId })
    .get("/brief-creator/files/upload-url")
    .json<{ fields: Record<string, string>; url: string }>();
}

export async function uploadFile(
  newFile: FileAsset,
  setAssets: React.Dispatch<React.SetStateAction<(FileAsset | LinkAsset)[]>>,
  setError: (error: null | string) => void,
  projectId: number,
): Promise<void> {
  try {
    if (newFile.file.size > MAX_FILE_SIZE) {
      setError(`File ${newFile.file.name} exceeds the maximum size of 200MB.`);
      updateFileById(newFile.id, { status: "error" }, setAssets);

      return;
    }

    const uploadUrlFields = await getUploadUrlFields(
      newFile.file.name,
      projectId,
      "1", // TODO: hardcoded because we don't have a requestId yet (will be implemented with NLC-82/NLC-151)
    );

    const formData = new FormData();

    for (const [key, value] of Object.entries(uploadUrlFields.fields)) {
      formData.append(key, value as string);
    }

    formData.append("file", newFile.file);

    const uploadResponse = await fetch(uploadUrlFields.url, {
      body: formData,
      method: "POST",
    });

    if (!uploadResponse.ok) {
      throw new Error(`Upload failed with status ${uploadResponse.status}`);
    }

    updateFileById(
      newFile.id,
      { awsKey: uploadUrlFields.fields.key, status: "success" },
      setAssets,
    );
  } catch (error) {
    console.error(`Error uploading file ${newFile.file.name}:`, error);
    updateFileById(newFile.id, { status: "error" }, setAssets);
  }
}
