import apiClient from "@/lib/api/client";

import type { FileAsset, LinkAsset } from "./uploadUtils";

export function updateFileById(
  fileId: string,
  fields: Partial<FileAsset>,
  setAssets: React.Dispatch<React.SetStateAction<(FileAsset | LinkAsset)[]>>,
): void {
  setAssets((prev) =>
    prev.map((asset) =>
      asset.id === fileId ? { ...asset, ...fields } : asset,
    ),
  );
}

export function findFileAssetByAwsKey(
  assets: Array<FileAsset | LinkAsset>,
  awsKey: string,
): FileAsset | undefined {
  return assets.find(
    (asset): asset is FileAsset => "awsKey" in asset && asset.awsKey === awsKey,
  );
}

export async function deleteFileFromServer(awsKey: string): Promise<void> {
  await apiClient
    .delete(`/brief-creator/files/delete-file/${encodeURIComponent(awsKey)}`)
    .json();
}

export function removeAssetFromState(
  assets: Array<FileAsset | LinkAsset>,
  awsKey: string,
): Array<FileAsset | LinkAsset> {
  return assets.filter(
    (asset) => !isFileAsset(asset) || asset.awsKey !== awsKey,
  );
}

export function markAssetAsError(
  assets: Array<FileAsset | LinkAsset>,
  awsKey: string,
): Array<FileAsset | LinkAsset> {
  return assets.map((asset) =>
    isFileAsset(asset) && asset.awsKey === awsKey
      ? { ...asset, status: "error" as const }
      : asset,
  );
}

function isFileAsset(asset: FileAsset | LinkAsset): asset is FileAsset {
  return "awsKey" in asset;
}
