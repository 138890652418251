export type DesignType = {
  category: string;
  id: number;
  isArchived: number;
  name: string;
  subCategoryId: number;
};

export const designTypes: DesignType[] = [
  {
    category: "graphic",
    id: 1,
    isArchived: 0,
    name: "A+/EBC",
    subCategoryId: 3,
  },
  {
    category: "graphic",
    id: 2,
    isArchived: 0,
    name: "Listing Images",
    subCategoryId: 3,
  },
  {
    category: "graphic",
    id: 3,
    isArchived: 0,
    name: "Background Removal",
    subCategoryId: 3,
  },
  {
    category: "graphic",
    id: 4,
    isArchived: 0,
    name: "Banners",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 5,
    isArchived: 0,
    name: "Blog Graphics",
    subCategoryId: 2,
  },
  {
    category: "graphic",
    id: 6,
    isArchived: 0,
    name: "Book Covers",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 7,
    isArchived: 0,
    name: "Catalog Design",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 8,
    isArchived: 0,
    name: "Brand Style Guide",
    subCategoryId: 8,
  },
  {
    category: "graphic",
    id: 9,
    isArchived: 1,
    name: "Brand Style Guide",
    subCategoryId: 8,
  },
  {
    category: "graphic",
    id: 10,
    isArchived: 0,
    name: "Brochure Design",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 11,
    isArchived: 0,
    name: "Business Cards",
    subCategoryId: 8,
  },
  {
    category: "graphic",
    id: 12,
    isArchived: 0,
    name: "Carousel Ads",
    subCategoryId: 1,
  },
  {
    category: "graphic",
    id: 13,
    isArchived: 1,
    name: "Postcards",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 14,
    isArchived: 0,
    name: "Cover Images",
    subCategoryId: 2,
  },
  {
    category: "graphic",
    id: 15,
    isArchived: 0,
    name: "Google Display Ads",
    subCategoryId: 1,
  },
  {
    category: "graphic",
    id: 16,
    isArchived: 1,
    name: "PDF",
    subCategoryId: 10,
  },
  {
    category: "graphic",
    id: 17,
    isArchived: 0,
    name: "Email Graphics",
    subCategoryId: 4,
  },
  {
    category: "graphic",
    id: 18,
    isArchived: 0,
    name: "Meta/Facebook Ads",
    subCategoryId: 1,
  },
  {
    category: "graphic",
    id: 19,
    isArchived: 0,
    name: "Feed Graphics",
    subCategoryId: 2,
  },
  {
    category: "graphic",
    id: 20,
    isArchived: 0,
    name: "Flyer Design",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 21,
    isArchived: 0,
    name: "Simple GIFs",
    subCategoryId: 1,
  },
  {
    category: "graphic",
    id: 22,
    isArchived: 0,
    name: "Icons",
    subCategoryId: 5,
  },
  {
    category: "graphic",
    id: 23,
    isArchived: 0,
    name: "Flat Illustrations",
    subCategoryId: 6,
  },
  {
    category: "graphic",
    id: 24,
    isArchived: 0,
    name: "Infographics",
    subCategoryId: 10,
  },
  {
    category: "graphic",
    id: 25,
    isArchived: 0,
    name: "Instagram Ads",
    subCategoryId: 1,
  },
  {
    category: "graphic",
    id: 26,
    isArchived: 0,
    name: "Invitations",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 27,
    isArchived: 0,
    name: "Stationary",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 28,
    isArchived: 0,
    name: "Logo Design",
    subCategoryId: 8,
  },
  {
    category: "graphic",
    id: 29,
    isArchived: 1,
    name: "Flyer Design",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 30,
    isArchived: 0,
    name: "Other",
    subCategoryId: 10,
  },
  {
    category: "graphic",
    id: 31,
    isArchived: 0,
    name: "PDF",
    subCategoryId: 10,
  },
  {
    category: "graphic",
    id: 32,
    isArchived: 0,
    name: "Package Design",
    subCategoryId: 7,
  },
  {
    category: "graphic",
    id: 33,
    isArchived: 0,
    name: "Photo Editing",
    subCategoryId: 3,
  },
  {
    category: "graphic",
    id: 34,
    isArchived: 0,
    name: "Pinterest Ads",
    subCategoryId: 1,
  },
  {
    category: "graphic",
    id: 35,
    isArchived: 0,
    name: "Podcast Cover Art",
    subCategoryId: 2,
  },
  {
    category: "graphic",
    id: 36,
    isArchived: 0,
    name: "Postcards",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 37,
    isArchived: 1,
    name: "PDF",
    subCategoryId: 10,
  },
  {
    category: "graphic",
    id: 38,
    isArchived: 0,
    name: "Product Mockups",
    subCategoryId: 3,
  },
  {
    category: "graphic",
    id: 39,
    isArchived: 1,
    name: "Flyer Design",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 40,
    isArchived: 1,
    name: "Banners",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 41,
    isArchived: 0,
    name: "Sales Sheets",
    subCategoryId: 10,
  },
  {
    category: "video",
    id: 42,
    isArchived: 0,
    name: "Meta/Facebook Ads",
    subCategoryId: 11,
  },
  {
    category: "graphic",
    id: 43,
    isArchived: 1,
    name: "Cover Images",
    subCategoryId: 2,
  },
  {
    category: "graphic",
    id: 44,
    isArchived: 1,
    name: "Feed Graphics",
    subCategoryId: 2,
  },
  {
    category: "graphic",
    id: 45,
    isArchived: 0,
    name: "Stickers",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 46,
    isArchived: 0,
    name: "T-Shirts",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 47,
    isArchived: 0,
    name: "Twitter Ads",
    subCategoryId: 1,
  },
  {
    category: "graphic",
    id: 48,
    isArchived: 1,
    name: "Website Graphics",
    subCategoryId: 5,
  },
  {
    category: "graphic",
    id: 49,
    isArchived: 0,
    name: "Website Graphics",
    subCategoryId: 5,
  },
  {
    category: "video",
    id: 50,
    isArchived: 0,
    name: "Listing Videos",
    subCategoryId: 3,
  },
  {
    category: "video",
    id: 51,
    isArchived: 0,
    name: "Advanced GIFs",
    subCategoryId: 12,
  },
  {
    category: "video",
    id: 52,
    isArchived: 1,
    name: "Simple Character Animations",
    subCategoryId: 12,
  },
  {
    category: "video",
    id: 53,
    isArchived: 0,
    name: "Video Openers/Titles",
    subCategoryId: 12,
  },
  {
    category: "video",
    id: 54,
    isArchived: 0,
    name: "Transcriptions",
    subCategoryId: 14,
  },
  {
    category: "video",
    id: 55,
    isArchived: 0,
    name: "Simple Character Animations",
    subCategoryId: 12,
  },
  {
    category: "video",
    id: 56,
    isArchived: 0,
    name: "Cinemagraphs",
    subCategoryId: 12,
  },
  {
    category: "video",
    id: 57,
    isArchived: 0,
    name: "Explainer w/B-roll",
    subCategoryId: 14,
  },
  {
    category: "video",
    id: 58,
    isArchived: 1,
    name: "Meta/Facebook Ads",
    subCategoryId: 11,
  },
  {
    category: "video",
    id: 59,
    isArchived: 0,
    name: "Animated Logos",
    subCategoryId: 12,
  },
  {
    category: "video",
    id: 60,
    isArchived: 0,
    name: "Text Overlays",
    subCategoryId: 12,
  },
  {
    category: "video",
    id: 61,
    isArchived: 1,
    name: "Mobile App Showcase",
    subCategoryId: 12,
  },
  {
    category: "video",
    id: 62,
    isArchived: 1,
    name: "Explainer w/B-roll",
    subCategoryId: 14,
  },
  {
    category: "video",
    id: 63,
    isArchived: 0,
    name: "Product Highlights",
    subCategoryId: 14,
  },
  {
    category: "video",
    id: 64,
    isArchived: 1,
    name: "UGC Mashups",
    subCategoryId: 14,
  },
  {
    category: "video",
    id: 65,
    isArchived: 1,
    name: "Meta/Facebook Ads",
    subCategoryId: 11,
  },
  {
    category: "video",
    id: 66,
    isArchived: 0,
    name: "UGC Mashups",
    subCategoryId: 14,
  },
  {
    category: "video",
    id: 67,
    isArchived: 0,
    name: "General Editing",
    subCategoryId: 14,
  },
  {
    category: "video",
    id: 68,
    isArchived: 0,
    name: "Canva Designs",
    subCategoryId: 11,
  },
  {
    category: "graphic",
    id: 69,
    isArchived: 1,
    name: "Meta/Facebook Ads",
    subCategoryId: 1,
  },
  {
    category: "video",
    id: 70,
    isArchived: 1,
    name: "Listing Videos",
    subCategoryId: 3,
  },
  {
    category: "graphic",
    id: 71,
    isArchived: 0,
    name: "Storefront Modules",
    subCategoryId: 3,
  },
  {
    category: "video",
    id: 72,
    isArchived: 1,
    name: "Listing Videos",
    subCategoryId: 3,
  },
  {
    category: "graphic",
    id: 73,
    isArchived: 0,
    name: "Brand Story",
    subCategoryId: 3,
  },
  {
    category: "graphic",
    id: 74,
    isArchived: 0,
    name: "Tiktok Ads",
    subCategoryId: 1,
  },
  {
    category: "graphic",
    id: 75,
    isArchived: 0,
    name: "LinkedIn Ads",
    subCategoryId: 1,
  },
  {
    category: "graphic",
    id: 76,
    isArchived: 0,
    name: "Story Graphics",
    subCategoryId: 2,
  },
  {
    category: "graphic",
    id: 77,
    isArchived: 0,
    name: "Meta/Facebook Banners",
    subCategoryId: 2,
  },
  {
    category: "graphic",
    id: 78,
    isArchived: 0,
    name: "Twitch Banners",
    subCategoryId: 2,
  },
  {
    category: "graphic",
    id: 79,
    isArchived: 0,
    name: "Twitter Banners",
    subCategoryId: 2,
  },
  {
    category: "graphic",
    id: 80,
    isArchived: 0,
    name: "Youtube Banners",
    subCategoryId: 2,
  },
  {
    category: "graphic",
    id: 81,
    isArchived: 0,
    name: "Youtube Thumbnails",
    subCategoryId: 2,
  },
  {
    category: "graphic",
    id: 82,
    isArchived: 0,
    name: "A+ Premium",
    subCategoryId: 3,
  },
  {
    category: "graphic",
    id: 83,
    isArchived: 0,
    name: "Lifestyle Images",
    subCategoryId: 3,
  },
  {
    category: "graphic",
    id: 84,
    isArchived: 0,
    name: "Product Infographs",
    subCategoryId: 3,
  },
  {
    category: "graphic",
    id: 85,
    isArchived: 0,
    name: "White Backgrounds",
    subCategoryId: 3,
  },
  {
    category: "graphic",
    id: 86,
    isArchived: 0,
    name: "Email Template",
    subCategoryId: 4,
  },
  {
    category: "graphic",
    id: 87,
    isArchived: 0,
    name: "Email Signatures",
    subCategoryId: 4,
  },
  {
    category: "graphic",
    id: 88,
    isArchived: 0,
    name: "Email Banners",
    subCategoryId: 4,
  },
  {
    category: "graphic",
    id: 89,
    isArchived: 0,
    name: "Email Header",
    subCategoryId: 4,
  },
  {
    category: "graphic",
    id: 90,
    isArchived: 0,
    name: "Email Footer",
    subCategoryId: 4,
  },
  {
    category: "graphic",
    id: 91,
    isArchived: 0,
    name: "Simple Landing Page",
    subCategoryId: 5,
  },
  {
    category: "graphic",
    id: 92,
    isArchived: 0,
    name: "Section Graphics",
    subCategoryId: 5,
  },
  {
    category: "graphic",
    id: 93,
    isArchived: 0,
    name: "Section Backgrounds",
    subCategoryId: 5,
  },
  {
    category: "graphic",
    id: 94,
    isArchived: 0,
    name: "Characters",
    subCategoryId: 6,
  },
  {
    category: "graphic",
    id: 95,
    isArchived: 0,
    name: "Mascots",
    subCategoryId: 6,
  },
  {
    category: "graphic",
    id: 96,
    isArchived: 0,
    name: "Charts/Graphs",
    subCategoryId: 6,
  },
  {
    category: "graphic",
    id: 97,
    isArchived: 0,
    name: "Packaging Insert",
    subCategoryId: 7,
  },
  {
    category: "graphic",
    id: 98,
    isArchived: 0,
    name: "Labels",
    subCategoryId: 7,
  },
  {
    category: "graphic",
    id: 99,
    isArchived: 0,
    name: "Supplement Labels",
    subCategoryId: 7,
  },
  {
    category: "graphic",
    id: 100,
    isArchived: 0,
    name: "Beverage Labels",
    subCategoryId: 7,
  },
  {
    category: "graphic",
    id: 101,
    isArchived: 0,
    name: "Brand Colors",
    subCategoryId: 8,
  },
  {
    category: "graphic",
    id: 102,
    isArchived: 0,
    name: "Patterns",
    subCategoryId: 8,
  },
  {
    category: "graphic",
    id: 103,
    isArchived: 0,
    name: "Vector Tracing",
    subCategoryId: 8,
  },
  {
    category: "graphic",
    id: 104,
    isArchived: 0,
    name: "Simple Pattern Design",
    subCategoryId: 8,
  },
  {
    category: "graphic",
    id: 105,
    isArchived: 0,
    name: "Poster Design",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 106,
    isArchived: 0,
    name: "Signage Design",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 107,
    isArchived: 0,
    name: "Vehicle Wraps",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 108,
    isArchived: 0,
    name: "Album Covers",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 109,
    isArchived: 0,
    name: "Certificates/Awards",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 110,
    isArchived: 0,
    name: "Menu Design",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 111,
    isArchived: 0,
    name: "Trade Show Banners",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 112,
    isArchived: 0,
    name: "Billboard Design",
    subCategoryId: 9,
  },
  {
    category: "graphic",
    id: 113,
    isArchived: 0,
    name: "Slide Deck Design",
    subCategoryId: 10,
  },
  {
    category: "graphic",
    id: 114,
    isArchived: 0,
    name: "Presentation Templates",
    subCategoryId: 10,
  },
  {
    category: "graphic",
    id: 115,
    isArchived: 0,
    name: "Presentation Graphics",
    subCategoryId: 10,
  },
  {
    category: "graphic",
    id: 116,
    isArchived: 0,
    name: "Newsletters",
    subCategoryId: 10,
  },
  {
    category: "graphic",
    id: 117,
    isArchived: 0,
    name: "eBook Cover",
    subCategoryId: 10,
  },
  {
    category: "graphic",
    id: 118,
    isArchived: 0,
    name: "Pitch Deck",
    subCategoryId: 10,
  },
  {
    category: "video",
    id: 119,
    isArchived: 0,
    name: "Tiktok Ads",
    subCategoryId: 11,
  },
  {
    category: "video",
    id: 120,
    isArchived: 0,
    name: "Google Display Ads",
    subCategoryId: 11,
  },
  {
    category: "video",
    id: 121,
    isArchived: 0,
    name: "Instagram Ads",
    subCategoryId: 11,
  },
  {
    category: "video",
    id: 122,
    isArchived: 0,
    name: "Pinterest Ads",
    subCategoryId: 11,
  },
  {
    category: "video",
    id: 123,
    isArchived: 0,
    name: "LinkedIn Ads",
    subCategoryId: 11,
  },
  {
    category: "video",
    id: 124,
    isArchived: 0,
    name: "Youtube Shorts Ads",
    subCategoryId: 11,
  },
  {
    category: "video",
    id: 125,
    isArchived: 0,
    name: "Twitter Ads",
    subCategoryId: 11,
  },
  {
    category: "video",
    id: 126,
    isArchived: 0,
    name: "Static Design Animations",
    subCategoryId: 12,
  },
  {
    category: "video",
    id: 127,
    isArchived: 0,
    name: "Icon Animations",
    subCategoryId: 12,
  },
  {
    category: "video",
    id: 128,
    isArchived: 0,
    name: "Captions",
    subCategoryId: 12,
  },
  {
    category: "video",
    id: 129,
    isArchived: 0,
    name: "Explainer Video",
    subCategoryId: 12,
  },
  {
    category: "video",
    id: 130,
    isArchived: 0,
    name: "Lottie Animations",
    subCategoryId: 12,
  },
  {
    category: "video",
    id: 131,
    isArchived: 0,
    name: "Dynamic Captions",
    subCategoryId: 12,
  },
  {
    category: "video",
    id: 132,
    isArchived: 0,
    name: "Simple Infographics",
    subCategoryId: 12,
  },
  {
    category: "video",
    id: 133,
    isArchived: 0,
    name: "Captioning",
    subCategoryId: 14,
  },
  {
    category: "video",
    id: 134,
    isArchived: 0,
    name: "English AI Voiceover",
    subCategoryId: 14,
  },
  {
    category: "video",
    id: 135,
    isArchived: 0,
    name: "Premium AI Voiceover",
    subCategoryId: 14,
  },
  {
    category: "video",
    id: 136,
    isArchived: 0,
    name: "Event Promos",
    subCategoryId: 14,
  },
  {
    category: "video",
    id: 137,
    isArchived: 0,
    name: "Condensing Long Videos",
    subCategoryId: 14,
  },
  {
    category: "video",
    id: 138,
    isArchived: 0,
    name: "Video Sales Letter",
    subCategoryId: 14,
  },
  {
    category: "video",
    id: 139,
    isArchived: 0,
    name: "Sizzle Reel",
    subCategoryId: 14,
  },
] as const;
