import {
  ComboBox,
  ComboBoxButton,
  ComboBoxGroup,
  ComboBoxInput,
  ComboBoxListBox,
  ComboBoxListItem,
  ComboBoxPopover,
  ComboBoxSection,
} from "@/components/combobox";
import { Label } from "@/components/field";
import { Text } from "@/components/text";

import type { DesignType } from "./consts/designTypes";

import { designTypes } from "./consts/designTypes";
import { designTypeSubCategories } from "./consts/designTypeSubCategories";

function getGroupedDesignTypes(): Record<string, DesignType[]> {
  const groupedDesignTypes: Record<string, DesignType[]> = {};

  // Initialize categories with empty arrays, respecting the order
  for (const subCategory of designTypeSubCategories) {
    groupedDesignTypes[subCategory.name] = [];
  }

  // Assign design types to their respective categories
  for (const designType of designTypes) {
    if (!designType.isArchived && designType.category === "graphic") {
      const subCategory = designTypeSubCategories.find(
        (subCategory) => subCategory.id === designType.subCategoryId,
      );

      if (subCategory) {
        groupedDesignTypes[subCategory.name]!.push(designType);
      }
    }
  }

  // Filter out empty subcategories
  return Object.fromEntries(
    Object.entries(groupedDesignTypes).filter(([_, types]) => types.length > 0),
  );
}

const nonEmptyGroupedDesignTypes = getGroupedDesignTypes();

export function SelectDesignType() {
  return (
    <ComboBox allowsEmptyCollection menuTrigger="focus">
      <Label requiredHint>Design Type</Label>
      <ComboBoxGroup>
        <ComboBoxInput placeholder="Select Design Type" />
        <ComboBoxButton />
      </ComboBoxGroup>

      <ComboBoxPopover>
        <ComboBoxListBox
          className="max-h-[291px]"
          renderEmptyState={() => <Text className="p-2">No results found</Text>}
        >
          {Object.entries(nonEmptyGroupedDesignTypes).map(
            ([subCategoryName, types]) => (
              <ComboBoxSection key={subCategoryName} title={subCategoryName}>
                {types.map((type) => (
                  <ComboBoxListItem id={type.id} key={type.name}>
                    {type.name}
                  </ComboBoxListItem>
                ))}
              </ComboBoxSection>
            ),
          )}
        </ComboBoxListBox>
      </ComboBoxPopover>
    </ComboBox>
  );
}
