import { useMutation } from "@tanstack/react-query";

import apiClient from "@/lib/api/client";

import type { LoginSubmissionData } from "./useLoginForm";

type LoginResponse = {
  agreement: boolean;
  cookies: string;
  message: string;
};

export function useLoginMutation() {
  return useMutation({
    mutationFn: async ({ email, password }: LoginSubmissionData) =>
      await apiClient
        .url("/auth/login")
        .post({ email, password })
        .json<LoginResponse>(),
  });
}
