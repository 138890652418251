import type { TooltipProps as RACTooltipProps } from "react-aria-components";

import React from "react";
import { mergeProps, useFocusable } from "react-aria";
import { OverlayArrow, Tooltip as RACTooltip } from "react-aria-components";

import { composeTailwindRenderProps } from "../utils";

export { TooltipTrigger } from "react-aria-components";

export interface TooltipProps extends Omit<RACTooltipProps, "children"> {
  children: React.ReactNode;
}

export function Tooltip({ children, ...props }: TooltipProps) {
  return (
    <RACTooltip
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        "group max-w-[320px] text-wrap text-pretty rounded-md bg-gray-950 px-lg py-md text-xs text-white font-semibold",
      )}
      offset={10}
    >
      <OverlayArrow>
        <svg
          className="fill-gray-950 stroke-gray-950 stroke-[0.5px] group-placement-left:-rotate-90 group-placement-right:rotate-90 group-placement-bottom:rotate-180"
          height={12}
          viewBox="0 0 8 8"
          width={12}
        >
          <path d="M0 0 L4 4 L8 0" />
        </svg>
      </OverlayArrow>
      {children}
    </RACTooltip>
  );
}

// https://argos-ci.com/blog/react-aria-migration
export function NonFousableTooltipTarget(props: {
  children: React.ReactElement;
}) {
  const triggerRef = React.useRef(null);
  const { focusableProps } = useFocusable(props.children.props, triggerRef);

  return React.cloneElement(
    props.children,
    mergeProps(focusableProps, { tabIndex: 0 }, props.children.props, {
      ref: triggerRef,
    }),
  );
}

export function NativeTooltip({
  title,
  ...props
}: { title: string } & JSX.IntrinsicElements["div"]) {
  return <div role="presentation" title={title} {...props} />;
}
