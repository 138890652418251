import { z } from "zod";

const config = z.object({
  VITE_BASE_API_URL: z.string(),
  VITE_BASE_URL: z.string(),
  VITE_PUBLIC_POSTHOG_HOST: z.string(),
  VITE_PUBLIC_POSTHOG_KEY: z.string(),
});

export const env = config.parse(import.meta.env);
