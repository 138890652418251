import { FieldError, Input, Label, TextField } from "@/components/field";
import {
  Select,
  SelectButton,
  SelectListBox,
  SelectListItem,
  SelectPopover,
} from "@/components/select";

import { CollaboratorsSelect } from "./CollaboratorsSelect";
import { PreferredDesignersSelect } from "./PreferredDesignersSelect";

export function TechnicalDetailsSection() {
  return (
    <div className="grid grid-cols-2 gap-x-3xl gap-y-lg">
      <span className="col-span-2 text-lg font-semibold">
        Technical Details
      </span>

      <Select placeholder="Select creative direction">
        <Label>Creative direction</Label>
        <SelectButton />
        <SelectPopover>
          <SelectListBox>
            <SelectListItem>Designer has creative freedom</SelectListItem>
            <SelectListItem>Follow brief exactly</SelectListItem>
          </SelectListBox>
        </SelectPopover>
      </Select>

      <TextField isRequired>
        <Label requiredHint>Sizes needed</Label>
        <Input placeholder="E.g. 1080x1080, 16x9, etc." />
        <FieldError />
      </TextField>

      <TextField isRequired>
        <Label requiredHint>File types</Label>
        <Input placeholder="E.g. JPG, PNG, PDF, etc." />
        <FieldError />
      </TextField>

      <PreferredDesignersSelect />

      <CollaboratorsSelect />
    </div>
  );
}
