import { FieldError } from "react-aria-components";

import { Input, Label, TextField } from "@/components/field";

import { BrandsSelect } from "./BrandsSelect";
import { SelectDesignType } from "./SelectDesignType";

export function IntroSection() {
  return (
    <div className="grid grid-cols-4 gap-x-3xl">
      <TextField className="col-span-2">
        <Label requiredHint>Request name</Label>
        <Input placeholder="Create a unique name for your request" />
        <FieldError />
      </TextField>
      <SelectDesignType />
      <BrandsSelect />
    </div>
  );
}
