import type { LinkProps, TextProps } from "react-aria-components";

import React from "react";
import { twMerge } from "tailwind-merge";

import { Link } from "../link";
import { composeTailwindRenderProps } from "../utils";

export function Text({
  children,
  className,
  elementType,
  ...props
}: TextProps) {
  return React.createElement(
    elementType ?? "p",
    {
      ...props,
      className: twMerge(
        "text-pretty text-base/6 text-muted sm:text-sm/6",
        className,
      ),
    },
    children,
  );
}

export function Strong({
  className,
  ...props
}: JSX.IntrinsicElements["strong"]) {
  return (
    <Text
      {...props}
      className={twMerge("font-medium", className)}
      elementType="strong"
    />
  );
}

export function Small({ className, ...props }: JSX.IntrinsicElements["small"]) {
  return (
    <Text
      {...props}
      className={twMerge("text-sm sm:text-xs", className)}
      elementType="small"
    />
  );
}

export function TextLink(props: LinkProps) {
  return (
    <Link
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        "underline underline-offset-4",
      )}
    />
  );
}
