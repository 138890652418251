import { z } from "zod";

export const detailedFormSchema = z.object({
  additionalGuidelines: z.string().max(1000, "Max 1000 characters").optional(),
  brandGuidelines: z.string().max(1000, "Max 1000 characters").optional(),
  creativeGoal: z.string().min(1, "Required").max(1000, "Max 1000 characters"),
  keyMessageAndCta: z
    .string()
    .min(1, "Required")
    .max(1000, "Max 1000 characters"),
  scope: z.string().min(1, "Required").max(1000, "Max 1000 characters"),
});

export const singleInputFormSchema = z.object({
  requestDescriptionHTML: z.string(),
  requestDescriptionText: z
    .string()
    .min(1, "Required")
    .max(1000, "Max 1000 characters"),
  textsForDesignHTML: z.string(),
  textsForDesignText: z
    .string()
    .min(1, "Required")
    .max(1000, "Max 1000 characters"),
});

export type DetailedFormData = z.infer<typeof detailedFormSchema>;
export interface SingleInputFormData {
  requestDescriptionHTML: string;
  requestDescriptionText: string;
  textsForDesignHTML: string;
  textsForDesignText: string;
}
export interface MappedSingleInputFormData {
  requestDescription: string;
  textsForDesign: string;
}
