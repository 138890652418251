import { AlertCircle } from "lucide-react";

export function SizeAlert() {
  return (
    <div className="flex gap-xl rounded-xl border border-brand-300 bg-brand-50 p-xl">
      <div className="grid size-4xl place-items-center rounded-full bg-brand-100">
        <AlertCircle className="text-brand-600" size={16} />
      </div>
      <div className="flex flex-col">
        <span className="text-sm font-medium text-brand-700">
          We only allow up to 25 files to be uploaded at once.
        </span>
        <span className="text-sm text-brand-600">
          For more uploads, use Google Drive, Dropbox, or similar services.
        </span>
      </div>
    </div>
  );
}
