import type {
  TagGroupProps as AriaTagGroupProps,
  TagProps as AriaTagProps,
  TagListProps,
} from "react-aria-components";

import {
  Tag as AriaTag,
  TagGroup as AriaTagGroup,
  Button,
  composeRenderProps,
  TagList as RACTagList,
} from "react-aria-components";
import { twMerge } from "tailwind-merge";

import { XIcon } from "../icons";
import { composeTailwindRenderProps, focusOutlineStyle } from "../utils";

export function TagGroup({ children, ...props }: AriaTagGroupProps) {
  return (
    <AriaTagGroup
      {...props}
      className={twMerge("flex flex-col gap-1", props.className)}
    >
      {children}
    </AriaTagGroup>
  );
}

export function TagList<T extends object>(props: TagListProps<T>) {
  return (
    <RACTagList
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        "flex flex-wrap gap-sm",
      )}
    />
  );
}

export function Tag({ children, ...props }: AriaTagProps) {
  const textValue = typeof children === "string" ? children : undefined;

  return (
    <AriaTag
      textValue={textValue}
      {...props}
      className={composeRenderProps(props.className, (className, renderProps) =>
        twMerge(
          "flex max-w-fit cursor-default items-center gap-[3px] rounded-sm border border-gray-300 pl-md pr-xs py-[3px] text-xs transition",
          renderProps.allowsRemoving && "pe-1",
          renderProps.isFocusVisible && [focusOutlineStyle, "outline-offset-1"],
          className,
        ),
      )}
    >
      {({ allowsRemoving }) => (
        <>
          {children}
          {allowsRemoving && (
            <Button
              className={composeRenderProps("", (className, renderProps) =>
                twMerge(
                  "flex cursor-default items-center justify-center rounded-full p-0.5 outline-0 text-gray-400 transition-[background-color] hover:bg-gray-900/10 pressed:bg-gray-900/20",
                  renderProps.isFocusVisible && focusOutlineStyle,
                  className,
                ),
              )}
              slot="remove"
            >
              <XIcon className="size-3"></XIcon>
            </Button>
          )}
        </>
      )}
    </AriaTag>
  );
}
