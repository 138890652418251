import type { DefaultError } from "@tanstack/react-query";

import { useQuery } from "@tanstack/react-query";
import { useSearch } from "@tanstack/react-router";

import { Label } from "@/components/field";
import {
  Select,
  SelectButton,
  SelectListBox,
  SelectListItem,
  SelectPopover,
} from "@/components/select";
import apiClient from "@/lib/api/client";

// TODO: replace with shared types when ready
type Brand = {
  id: string;
  textValue: string;
};

type Response = {
  brands: {
    id: string;
    name: string;
  }[];
};

type MappedResponse = Brand[];

function useGetBrands(projectId: number) {
  return useQuery<Response, DefaultError, MappedResponse>({
    queryFn: async () =>
      await apiClient.url(`/brands?projectId=${projectId}`).get().json(),
    queryKey: ["brands"],
    select: (data) =>
      data.brands.map(({ id, name }) => ({ id, textValue: name })),
  });
}

export function BrandsSelect() {
  const { ["project-id"]: projectId } = useSearch({
    from: "/requests/create",
  });

  const { data: brands, isLoading } = useGetBrands(projectId);

  const placeholder = isLoading
    ? "Loading..."
    : brands?.length === 0
      ? "No results"
      : "Select Brand";

  return (
    <Select placeholder={placeholder}>
      <Label>Choose a brand</Label>
      <SelectButton />
      <SelectPopover>
        <SelectListBox items={brands}>
          {(item) => (
            <SelectListItem id={item.id}>{item.textValue}</SelectListItem>
          )}
        </SelectListBox>
      </SelectPopover>
    </Select>
  );
}
