// https://www.jacobparis.com/content/react-as-child
import React from "react";
import { twMerge } from "tailwind-merge";

export type AsChildProps<DefaultElementProps> =
  | { asChild: true; children: React.ReactNode }
  | ({ asChild?: false } & DefaultElementProps);

export function Slot({
  children,
  ...props
}: {
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLElement>) {
  if ("asChild" in props) {
    delete props.asChild;
  }

  if (React.isValidElement(children)) {
    return React.cloneElement(children, {
      ...props,
      ...children.props,
      className: twMerge(props.className, children.props.className),
      style: {
        ...props.style,
        ...children.props.style,
      },
    });
  }
  if (React.Children.count(children) > 1) {
    React.Children.only(null);
  }

  return null;
}
