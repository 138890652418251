import type { Key } from "react-aria-components";
import type { UseFormReturn } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import type { DetailedFormData, SingleInputFormData } from "../schemas";

import { detailedFormSchema, singleInputFormSchema } from "../schemas";

export function useDetailedForm() {
  return useForm<DetailedFormData>({
    defaultValues: {
      additionalGuidelines: "",
      brandGuidelines: "",
      creativeGoal: "",
      keyMessageAndCta: "",
      scope: "",
    },
    resolver: zodResolver(detailedFormSchema),
  });
}

export function useSingleInputForm() {
  return useForm<SingleInputFormData>({
    defaultValues: {
      requestDescriptionHTML: "",
      requestDescriptionText: "",
      textsForDesignHTML: "",
      textsForDesignText: "",
    },
    resolver: zodResolver(singleInputFormSchema),
  });
}

const getFilledMandatoryFieldsCount = (
  formType: Key,
  detailedMethods: UseFormReturn<DetailedFormData>,
  singleInputMethods: UseFormReturn<SingleInputFormData>,
) => {
  const fields =
    formType === "detailed"
      ? (["scope", "creativeGoal", "keyMessageAndCta"] as const)
      : (["requestDescriptionText", "textsForDesignText"] as const);

  const methods =
    formType === "detailed" ? detailedMethods : singleInputMethods;

  return fields.filter((field) =>
    methods.watch(field as keyof (DetailedFormData | SingleInputFormData)),
  ).length;
};

export function useFormState() {
  const [formType, setFormType] = useState<Key>("detailed");
  const detailedMethods = useDetailedForm();
  const singleInputMethods = useSingleInputForm();

  const handleFormTypeChange = useCallback((newFormType: Key) => {
    setFormType(newFormType);
  }, []);

  const totalMandatoryFields = formType === "detailed" ? 3 : 2;
  const filledMandatoryFields = getFilledMandatoryFieldsCount(
    formType,
    detailedMethods,
    singleInputMethods,
  );

  const getCurrentFormMethods = useCallback(
    () => (formType === "detailed" ? detailedMethods : singleInputMethods),
    [formType, detailedMethods, singleInputMethods],
  );

  return {
    detailedMethods,
    filledMandatoryFields,
    formType,
    getCurrentFormMethods,
    handleFormTypeChange,
    singleInputMethods,
    totalMandatoryFields,
  };
}

export function useCreateRequestForm() {
  const formState = useFormState();
  const { formType, getCurrentFormMethods } = formState;

  const onSubmit = useCallback(async () => {
    const methods = getCurrentFormMethods();
    const isValid = await methods.trigger();

    if (!isValid) {
      return null;
    }

    if (formType === "single") {
      const data = methods.getValues() as SingleInputFormData;

      return {
        data: {
          requestDescription: data.requestDescriptionText,
          textsForDesign: data.textsForDesignText,
        },
        formType: "single" as const,
      };
    }

    if (formType === "detailed") {
      const data = methods.getValues() as DetailedFormData;

      return {
        data,
        formType: "detailed" as const,
      };
    }

    return null;
  }, [formType, getCurrentFormMethods]);

  return {
    ...formState,
    onSubmit,
  };
}
