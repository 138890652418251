import type { HeadingProps as RACHeadingProps } from "react-aria-components";

import React from "react";
import { FocusScope } from "react-aria";
import { Heading as RACHeading } from "react-aria-components";
import { twMerge } from "tailwind-merge";

import type { DisplayLevel } from "../utils";

import { displayLevels } from "../utils";

type BaseHeadingProps = {
  autoFocus?: boolean;
  displayLevel?: DisplayLevel;
};

export type HeadingProps = {
  elementType?: never;
  level?: DisplayLevel;
} & RACHeadingProps;

type CustomElement = {
  elementType: "div";
  level?: never;
} & JSX.IntrinsicElements["div"];

export const Heading = React.forwardRef<
  HTMLDivElement | HTMLHeadingElement,
  BaseHeadingProps & (CustomElement | HeadingProps)
>(function Heading({ autoFocus, elementType, ...props }, ref) {
  if (elementType) {
    const { className, displayLevel = 1, ...restProps } = props;

    if (autoFocus) {
      return (
        <FocusScope autoFocus>
          <div
            {...restProps}
            className={twMerge(
              [displayLevels[displayLevel], "outline-none"],
              className,
            )}
            ref={ref}
          />
        </FocusScope>
      );
    }

    return (
      <div
        {...restProps}
        className={twMerge(displayLevels[displayLevel], className)}
        ref={ref}
      />
    );
  }

  const { className, displayLevel, level = 1, ...restProps } = props;

  if (autoFocus) {
    return (
      <FocusScope autoFocus>
        <RACHeading
          {...restProps}
          className={twMerge(
            [displayLevels[displayLevel ?? level], "outline-none"],
            className,
          )}
          level={level}
          ref={ref}
        />
      </FocusScope>
    );
  }

  return (
    <RACHeading
      {...restProps}
      className={twMerge(displayLevels[displayLevel ?? level], className)}
      level={level}
      ref={ref}
    />
  );
});

export const SubHeading = React.forwardRef<
  HTMLDivElement,
  JSX.IntrinsicElements["div"]
>(function SubHeading({ className, ...props }, ref) {
  return (
    <div
      {...props}
      className={twMerge("text-sm/6 text-muted", className)}
      ref={ref}
    />
  );
});
