import type { Control, FieldPath, FieldValues } from "react-hook-form";

import { Controller } from "react-hook-form";

import type { TiptapEditorProps } from "@/components/ui/TiptapEditor";

import { TiptapEditor } from "@/components/ui/TiptapEditor";

interface ControlledTiptapEditorProps<T extends FieldValues>
  extends Omit<TiptapEditorProps, "name" | "onChange"> {
  control: Control<T>;
  name: FieldPath<T>;
  onChangeHTML?: (html: string) => void;
  onChangeText?: (text: string) => void;
}

export function ControlledTiptapEditor<T extends FieldValues>({
  control,
  name,
  onChangeHTML,
  onChangeText,
  ...props
}: ControlledTiptapEditorProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <TiptapEditor
          {...props}
          errorMessage={error?.message}
          isInvalid={!!error}
          onChange={(value) => {
            onChangeText?.(value);
          }}
          onChangeHTML={(html) => {
            onChangeHTML?.(html);
          }}
          value={field.value}
        />
      )}
    />
  );
}
