import type {
  ButtonProps as RACButtonProps,
  ToggleButtonProps as RACToggleButtonProps,
} from "react-aria-components";

import { Loader } from "lucide-react";
import React from "react";
import {
  composeRenderProps,
  Button as RACButton,
  ToggleButton as RACToggleButton,
} from "react-aria-components";
import { twMerge } from "tailwind-merge";

import type { AsChildProps } from "../slot";

import { Slot } from "../slot";
import { focusOutlineStyle } from "../utils";

type Color = "color" | "destructive" | "gray";

type Size = "2xl" | "lg" | "md" | "sm" | "xl";

type Variant = "plain" | "primary" | "secondary" | "tertiary" | "unstyled";

export type BasicButtonProps = {
  className?: string;
  color?: Color;
  isCustomLoading?: boolean;
  isIconOnly?: boolean;
  isLoading?: boolean;
  loadingLabel?: string;
  size?: Size;
  variant?: Variant;
};

export type ButtonProps = AsChildProps<RACButtonProps> & BasicButtonProps;

export type ButtonWithoutAsChildProps = BasicButtonProps & RACButtonProps;

const buttonVariants = {
  base: "group relative inline-flex justify-center items-center whitespace-nowrap rounded-md outline-none font-semibold focus-visible:outline-4 focus-visible:outline-offset-0",
  plain: "",
  primary: "",
  secondary: "",
  tertiary: "",
};

const buttonSizes = {
  "2xl": {
    button:
      "text-xl px-[22px] py-xl gap-x-[10px] [&_svg:not([class*=size-])]:size-[24px]",
    iconOnly: "p-xl [&_svg:not([class*=size-])]:size-[24px]",
  },
  lg: {
    button:
      "text-md px-xl py-[10px] gap-x-sm [&_svg:not([class*=size-])]:size-[20px]",
    iconOnly: "p-lg [&_svg:not([class*=size-])]:size-[20px]",
  },
  md: {
    button:
      "text-sm px-[14px] py-[10px] gap-x-xs [&_svg:not([class*=size-])]:size-[20px]",
    iconOnly: "p-[10px] [&_svg:not([class*=size-])]:size-[20px]",
  },
  sm: {
    button:
      "text-sm px-lg py-md gap-x-xs [&_svg:not([class*=size-])]:size-[20px]",
    iconOnly: "p-md [&_svg:not([class*=size-])]:size-[20px]",
  },
  xl: {
    button:
      "text-md px-[18px] py-lg gap-x-sm [&_svg:not([class*=size-])]:size-[20px]",
    iconOnly: "p-[14px] [&_svg:not([class*=size-])]:size-[20px]",
  },
};

const buttonColors = {
  color: {
    plain: "",
    primary:
      "bg-brand-600 text-white hover:bg-brand-700 focus-visible:outline-brand-600/25 disabled:bg-gray-100 disabled:text-gray-400 disabled:border-gray-200",
    secondary:
      "bg-white text-brand-600 hover:bg-brand-50 border border-brand-300 focus-visible:outline-brand-600/25 disabled:bg-white disabled:text-gray-400 disabled:border-gray-200",
    tertiary:
      "bg-white hover:bg-brand-50 text-brand-600 focus-visible:outline-none disabled:bg-white disabled:text-gray-400",
  },
  destructive: {
    plain: "",
    primary:
      "bg-red-600 text-white hover:bg-red-700 focus-visible:outline-red-600/25 disabled:bg-gray-100 disabled:text-gray-400 disabled:border-gray-200",
    secondary:
      "bg-white text-red-600 hover:bg-red-50 border border-red-300 focus-visible:outline-red-600/25 disabled:bg-white disabled:text-gray-400 disabled:border-gray-200",
    tertiary:
      "bg-white hover:bg-red-50 text-red-600 focus-visible:outline-none disabled:bg-white disabled:text-gray-400",
  },
  gray: {
    plain: "",
    primary: "",
    secondary:
      "bg-white text-gray-700 hover:bg-gray-50 border border-gray-300 focus-visible:outline-gray-300/25 disabled:bg-white disabled:text-gray-400 disabled:border-gray-200",
    tertiary:
      "hover:bg-gray-25 text-gray-600 focus-visible:outline-none disabled:text-gray-400",
  },
};

function buttonStyle({
  color = "color",
  isIconOnly,
  size = "md",
  variant = "primary",
}: BasicButtonProps) {
  if (variant === "unstyled") {
    return "relative outline-none";
  }

  const buttonType = isIconOnly ? "iconOnly" : "button";

  return twMerge(
    buttonVariants.base,
    buttonVariants[variant],
    buttonColors[color][variant],
    buttonSizes[size][buttonType],
  );
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props, ref) {
    const {
      asChild,
      children,
      color,
      isCustomLoading,
      isIconOnly,
      isLoading,
      loadingLabel,
      size,
      variant,
      ...buttonProps
    } = props;

    if (asChild) {
      return <Slot className={buttonStyle(props)}>{children}</Slot>;
    }

    return (
      <>
        <RACButton
          {...buttonProps}
          className={composeRenderProps(
            props.className,
            (className, renderProps) =>
              twMerge(
                buttonStyle({ color, isIconOnly, isLoading, size, variant }),
                renderProps.isFocusVisible && focusOutlineStyle,
                isLoading && !isCustomLoading && "text-transparent",
                className,
              ),
          )}
          data-variant={variant ?? "solid"}
          ref={ref}
        >
          {(renderProps) => (
            <>
              {isLoading && !isCustomLoading && (
                <Loader
                  className={twMerge(
                    "absolute flex h-full items-center justify-center animate-spin",
                    isLoading ? "flex" : "hidden",
                  )}
                />
              )}

              {typeof children === "function"
                ? children(renderProps)
                : children}
            </>
          )}
        </RACButton>

        <span aria-live="polite" className="sr-only">
          {isLoading ? loadingLabel : ""}
        </span>
      </>
    );
  },
);

export type ToggleButtonProps = BasicButtonProps & RACToggleButtonProps;

export function ToggleButton(props: ToggleButtonProps) {
  const { className, ...toggleButtonProps } = props;

  return (
    <RACToggleButton
      {...toggleButtonProps}
      className={composeRenderProps(
        className,
        (baseClassName, { isFocusVisible }) =>
          twMerge(
            buttonStyle(props),
            isFocusVisible && focusOutlineStyle,
            baseClassName,
          ),
      )}
    />
  );
}
