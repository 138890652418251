import type { Editor } from "@tiptap/react";
import type { ComponentProps } from "react";

import clsx from "clsx";

import BoldIcon from "./icons/bold.svg?react";
import BulletListIcon from "./icons/bullet-list.svg?react";
import ItalicIcon from "./icons/italic.svg?react";
import OrderedListIcon from "./icons/ordered-list.svg?react";

type MenuBarButtonProps = {
  icon: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
} & ComponentProps<"button">;

function MenuBarButton({
  icon,
  isActive,
  onClick,
  ...buttonProps
}: MenuBarButtonProps) {
  return (
    <button
      className={clsx(
        isActive && "bg-gray-50 text-gray-500",
        "size-4xl overflow-hidden rounded-sm text-gray-400 hover:bg-gray-50",
      )}
      onClick={onClick}
      {...buttonProps}
    >
      {icon}
    </button>
  );
}

type MenuBarProps = {
  disabled: boolean;
  editor: Editor;
};

export function MenuBar({ disabled, editor }: MenuBarProps) {
  if (!editor) {
    return null;
  }

  return (
    <div className="flex gap-xs rounded-t-md border-b border-gray-300 bg-white p-md">
      <MenuBarButton
        aria-label="Bold"
        disabled={disabled}
        icon={<BoldIcon />}
        isActive={!disabled && editor.isActive("bold")}
        onClick={() => editor.chain().focus().toggleBold().run()}
      />
      <MenuBarButton
        aria-label="Italic"
        disabled={disabled}
        icon={<ItalicIcon />}
        isActive={!disabled && editor.isActive("italic")}
        onClick={() => editor.chain().focus().toggleItalic().run()}
      />
      <MenuBarButton
        aria-label="Bullet list"
        disabled={disabled}
        icon={<BulletListIcon />}
        isActive={!disabled && editor.isActive("bulletList")}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      />
      <MenuBarButton
        aria-label="Ordered list"
        disabled={disabled}
        icon={<OrderedListIcon />}
        isActive={!disabled && editor.isActive("orderedList")}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      />
    </div>
  );
}
